import React, { useState } from "react";
import Footertext from "../components/includes/FooterText";
import FormTitle from "../components/includes/FormTitle";
import Button from "../components/core/button";
import { useForm, reset } from "react-hook-form";
import Input from "../components/core/input";
import Select from "../components/core/Select";
import { sendRequest } from "../services/apiService";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const CountryList = [
  {
    optionName: "United States",
    optionValue: "amazon.com",
  },
  {
    optionName: "United Kingdom",
    optionValue: "amazon.co.uk",
  },
  {
    optionName: "France",
    optionValue: "amazon.fr",
  },
  {
    optionName: "Germany",
    optionValue: "amazon.de",
  },
  {
    optionName: "Italy",
    optionValue: "amazon.it",
  },
  {
    optionName: "Australia",
    optionValue: "amazon.com.au",
  },
  {
    optionName: "Belgium",
    optionValue: "amazon.com.be",
  },
  {
    optionName: "Brazil",
    optionValue: "amazon.com.br",
  },
  {
    optionName: "Canada",
    optionValue: "amazon.ca",
  },
  {
    optionName: "China",
    optionValue: "amazon.cn",
  },
  {
    optionName: "Egypt",
    optionValue: "amazon.eg",
  },
  {
    optionName: "India",
    optionValue: "amazon.in",
  },
  {
    optionName: "Japan",
    optionValue: "amazon.co.jp",
  },
  {
    optionName: "Mexico",
    optionValue: "amazon.com.mx",
  },
  {
    optionName: "Netherlands",
    optionValue: "amazon.nl",
  },
  {
    optionName: "Poland",
    optionValue: "amazon.pl",
  },
  {
    optionName: "Saudi Arabia",
    optionValue: "amazon.sa",
  },
  {
    optionName: "Singapore",
    optionValue: "amazon.sg",
  },
  {
    optionName: "Spain",
    optionValue: "amazon.es",
  },
  {
    optionName: "Sweden",
    optionValue: "amazon.se",
  },
  {
    optionName: "Turkey",
    optionValue: "amazon.com.tr",
  },
  {
    optionName: "UAE",
    optionValue: "amazon.ae",
  },
];
const StarsList = [
  {
    optionName: "All Stars",
    optionValue: "all_stars",
  },
  {
    optionName: "All Negative",
    optionValue: "all_critical",
  },
  {
    optionName: "All Positive",
    optionValue: "all_positive",
  },
  {
    optionName: "Five Star",
    optionValue: "five_star",
  },
  {
    optionName: "Four Star",
    optionValue: "four_star",
  },
  {
    optionName: "Three Star",
    optionValue: "three_star",
  },
  {
    optionName: "Two Star",
    optionValue: "two_star",
  },
  {
    optionName: "One Star",
    optionValue: "one_star",
  },
];
const NUMBER_OF_SUGGESTIONS = 5;
const AddAsin = () => {
  // move this logic to a zustand state
  const [showLoader, setShowLoader] = useState(false);

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({ mode: "onChange" });
  const suggestions_list = [...Array(NUMBER_OF_SUGGESTIONS + 1).keys()]
    .slice(1)
    .map((i) => {
      return { optionName: i, optionValue: i };
    });

  const onSubmit = async (data) => {
    setShowLoader(true);

    const response = await sendRequest(data, "add_asin");
    setShowLoader(false);
    if (response.status === 200) {
      toast.info("Asin already exists!", {
        position: "top-center",
      });
      return;
    } else if (response.status === 201) {
      toast.success("Asin added!", {
        position: "top-center",
      });
      return;
    } else {
      toast.error(`Something went wrong: Error ${response.status}`, {
        position: "top-center",
      });
      return;
    }
  };

  return (
    <>
      <div
        className={`container-xxl main__wrapper`}
        id="generator_form_container"
      >
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 col-sm-8">
            <FormTitle
              title="Add Asin"
              subTitle="Fill the necessary field to proceed"
            />
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="form__container mb-4"
            >
              <Input
                inputProps={register("asin", {
                  required: {
                    value: true,
                    message: "Please fill in the ASIN field",
                  },
                  minLength: {
                    value: 10,
                    message: "Please enter a 10-digit ASIN",
                  },
                  maxLength: {
                    value: 10,
                    message: "Please enter a 10-digit ASIN",
                  },
                })}
                Label="Enter ASIN #(Required):"
                placeholder="Enter the exact Amazon ASIN"
                errors={errors}
                inputId="asin"
              />
              <Select
                SelectData={CountryList}
                label="Country (Amazon Domain):"
                selectId="amazon_domain"
                inputProps={register("amazon_domain")}
              />
              <Button
                disabled={isSubmitting}
                label="Submit"
                type="submit"
                additionalClass="mt-3"
              />
            </form>
            <ToastContainer />

            <Footertext />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAsin;
