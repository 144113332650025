import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { useIsAuthenticated } from "@azure/msal-react";
import { BookType, Bot, Database, Image, Pencil, Star } from "lucide-react";
import { useState } from "react";
export function Homepage() {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  if (!isAuthenticated) {
    navigate("/");
  }
  const links = [
    {
      url: "/review-analysis-db",
      title: "Amazon Review Analyzer DB (Beta)",
      desc: "Turns customer feedback into clear insights, highlighting product strengths and weaknesses at a glance. Make smarter buying decisions with quick, actionable analysis!",
      state: { bot_name: "chat_service" },
      img: <Database className="w-full h-full p-10"></Database>,
    },
    {
      url: "/results",
      title: "Speak with UB AI Assistant",
      state: { bot_name: "chat_service" },
      desc: "",
      img: <Bot className="w-full h-full p-10"></Bot>,
    },
    {
      url: "/agent",
      title: "Speak with UB SQL Agent",
      state: { bot_name: "sql_bot_chat_service" },
      desc: "",
      img: <Bot className="w-full h-full p-10"></Bot>,
    },
    {
      url: "/results",
      title: "Speak with UB CS Assistant",
      state: { bot_name: "customer_chat_service" },
      desc: "Customer Service AI Assistant",
      img: <Bot className="w-full h-full p-10"></Bot>,
    },
    {
      url: "/results",
      title: "Speak with Compliance Bot",
      desc: "Compliance AI Assistant",
      state: { bot_name: "compliance_chat_service" },
      img: <Bot className="w-full h-full p-10"></Bot>,
    },
  ];
  return (
    <div className="px-20 pt-10 w-[1400px] justify-center self-center">
      <div className="justify-center">{message}</div>
      <h1 className="flex text-2xl font-bold tracking-tighter sm:text-5xl xl:text-4xl/none justify-center">
        Tools
      </h1>

      <section className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-1 md:gap-6 md:p-6 justify-center self-center">
        {links.map((link) => (
          <Link
            to={link.url}
            state={link.state}
            className="border homepage-card-bg relative group overflow-hidden rounded-lg shadow-lg hover:shadow-xl transition-transform duration-300 ease-in-out hover:-translate-y-2"
          >
            <div width={400} height={300} className="w-full h-48 object-cover">
              {link.img}
            </div>
            <div className="p-4">
              <h3 className="text-lg font-semibold truncate">{link.title}</h3>
              <p className=" line-clamp-2">{link.desc}</p>
            </div>
          </Link>
        ))}
      </section>
    </div>
  );
}
