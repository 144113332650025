import React, { useState, useEffect } from "react";
import loaderImg from "../assets/images/loader.gif";
import Footertext from "../components/includes/FooterText";
import Loader from "../components/includes/Loader";
import CopyButton from "../components/includes/CopyButton";
import RegenerateButton from "../components/includes/RegenrateButton";
import { useForm } from "react-hook-form";
import Input from "../components/core/input";
import { sendRequest } from "../services/apiService";
import ChatBox from "../components/core/chatbox";
import { toast, ToastContainer } from "react-toastify";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Constants } from "@/constants";
const ReviewResult = ({
  response: {
    suggestions,
    review_list,
    prompt,
    title,
    num_reviews,
    summary,
    thread_id,
  },
  regenerateFunction,
  asin,
}) => {
  const [messages, setMessages] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  const handleCopy = () => {
    const contentDiv = document.getElementById("contentToCopy");
    const copyMessage = document.getElementById("copyMessage");
    const content = contentDiv.textContent;

    const textarea = document.createElement("textarea");
    textarea.value = content;
    document.body.appendChild(textarea);

    textarea.select();
    document.execCommand("copy");

    document.body.removeChild(textarea);

    copyMessage.textContent = "Text has been copied";

    setTimeout(() => {
      copyMessage.textContent = "";
    }, 1500);
  };
  // Check if suggestions array is undefined
  // if (!suggestions) {
  //   return <div>No suggestions available</div>;
  // }
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({ mode: "onChange" });
  const onSubmit = async (data, e) => {
    e.target.reset();
    const messageWithSender = { sender: "user", message: data.message };
    setShowLoader(true);
    setMessages(() => [...messages, messageWithSender]);
    const params = { thread_id: thread_id, message: data.message };
    const resp = await sendRequest(params, "chat");
    if (resp.status == 500) {
      toast.error(`Something went wrong: Error ${resp.status}`, {
        position: "top-center",
      });
      setShowLoader(false);

      return;
    }
    let attachments_list = [];
    let content = [];
    let images = [];
    if ("attachments" in resp.data) {
      resp.data.content.map((c) => {
        if (c.type == "text") {
          content.push(c.text.value);
        } else if (c.type == "image_file") {
          images.push(
            <img
              src={`${Constants.apiUrl}/files/${c.image_file.file_id}?type=image`}
              style={{ width: "500px" }}
            />
          );
        }
      });
      resp.data.attachments.forEach((attachment) => {
        attachments_list.push(
          <a href={`${Constants.apiUrl}/files/${attachment.file_id}`}>
            Download file here
          </a>
        );
      });
    } else {
      content.push(JSON.stringify(resp.data.content[0].text.value));
    }

    setShowLoader(false);
    setMessages([
      ...messages,
      messageWithSender,
      {
        sender: "ai",
        message: (
          <div>
            <div>{images.map((e) => e)}</div>
            <Markdown remarkPlugins={[remarkGfm]}>
              {content.map((e) => e).join("")}
            </Markdown>
            <div>{attachments_list.map((e) => e)}</div>
          </div>
        ),
      },
    ]);
  };

  return (
    <>
      <ToastContainer />
      <div className="container-xxl main__wrapper" id="results_data">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="mb-5">
              <div className="row mb-4">
                <div className="col-sm-6">
                  <h2 className="page__head">Results </h2>
                  <h6>
                    {" "}
                    <b>Asin: </b>
                    {asin} <br />
                    <b>Title: </b>
                    {title} <br />
                    <b>Number Reviews: </b> {num_reviews}{" "}
                  </h6>
                </div>
                <div className="col-sm-6 position-relative d-flex justify-content-end">
                  <CopyButton clickFunction={handleCopy} />
                  <p id="copyMessage" className="copy_pop"></p>
                  <RegenerateButton regenerateFunction={regenerateFunction} />
                </div>
              </div>
              <div className="suggestion p-4" id="contentToCopy">
                {suggestions.map((suggestion, index) => (
                  <React.Fragment key={index}>
                    {suggestion.startsWith("Suggestion 1") ||
                    suggestion.startsWith("Suggestion") ? (
                      <h3>{suggestion}</h3>
                    ) : suggestion.startsWith("•") ? (
                      <>
                        &emsp;{suggestion}
                        <br />
                      </>
                    ) : (
                      <p>{suggestion}</p>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
            <ChatBox messages={messages} showLoader={showLoader} />

            <div className="summary review_data p-4 mb-4">
              <h2 className="summary__title">Prompt</h2>
              <div className="summary__data">
                <p className="summary__item">Prompt: {prompt}</p>
              </div>
            </div>
            <div className="summary review_data p-4 mb-4">
              <h2 className="summary__title">Summary of all Review Data</h2>
              <div className="summary__data">
                <p className="summary__item">
                  Average Rating: {summary?.rating}
                </p>
                <p className="summary__item">
                  Total Ratings: {summary?.ratings_total}
                </p>
                <p className="summary__item">
                  Total Reviews: {summary?.reviews_total}
                </p>
                {/* <p className="summary__item">Rating Breakdown: {{summary['rating_breakdown']}}</p> */}
                <div className="summary__item">
                  Rating Breakdown:
                  <ul>
                    <li>
                      Five Star: {summary?.rating_breakdown?.five_star?.count}
                    </li>
                    <li>
                      Four Star: {summary?.rating_breakdown?.four_star?.count}
                    </li>
                    <li>
                      Three Star: {summary?.rating_breakdown?.three_star?.count}
                    </li>
                    {/* Add more breakdowns as needed */}
                  </ul>
                </div>
              </div>
            </div>
            <div>
              <h2 className="page__head"> Raw Review Data</h2>
              <div className="review_data p-4 m-4">
                {review_list.map((review, index) => (
                  <div className="review" key={index}>
                    <h3 className="review__title">
                      Review #{index + 1}. Review Title: {review[0]}
                    </h3>
                    <p className="review__body">{review[1]}</p>
                    <ul className="review__details">
                      <li className="review__detail">Rating: {review[2]}</li>
                      <li className="review__detail">Date: {review[3]}</li>
                    </ul>
                  </div>
                ))}
              </div>
            </div>
            <Footertext />
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{
              position: "fixed",
              bottom: 0,
              width: "87%",
              marginBottom: "50px",
            }}
          >
            <Input
              inputProps={register("message")}
              placeholder="Chat here..."
              errors={errors}
              inputId="message"
            />
          </form>
        </div>
      </div>
    </>
  );
};

export default ReviewResult;
