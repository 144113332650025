import { FiLoader } from "react-icons/fi";

export default function Button({ disabled = false, isSubmitting, label, onClick, type, additionalClass }) {
  return (
    <button
      disabled={disabled}
      type={type ? type : "button"}
      className={`btn ${isSubmitting ? "isSubmitting" : "btn__theme"} ${additionalClass}`}
      onClick={onClick}>
      <span className={`${isSubmitting ? 'flex items-center gap-x-2' : ''}`}>
        {isSubmitting ? <FiLoader size={18} color="#fff" className="spin_animation" /> : label}
      </span>
    </button>
  );
}
