/**
 * v0 by Vercel.
 * @see https://v0.dev/t/ZSekWaUVMem
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */
import { Button } from "@/components/ui/button";
import { Link, useNavigate } from "react-router-dom";
import AILogo from "assets/images/ai.svg";
import Logo from "assets/images/unybrands.svg";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useState } from "react";
import { sendRequest } from "../../services/apiService";

import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react";
import PromptBank from "./PromptBank";
import { Input } from "../ui/input";

export default function Header() {
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [rawPrompts, setRawPrompts] = useState([]);
  const [filteredPrompts, setFilteredPrompts] = useState([]);
  const handleClose = () => setShow(false);

  const onChange = (v) => {
    const value = v.target.value.toLowerCase();
    let data = rawPrompts;
    if (value != "") {
      data = rawPrompts.filter((prompt) => {
        return (
          prompt.title.toLowerCase().includes(value) ||
          prompt.prompt.toLowerCase().includes(value) ||
          prompt.category.toLowerCase().includes(value)
        );
      });
    }
    const reduced = data.reduce((accumulator, prompt) => {
      let acc = accumulator[prompt["category"]] ?? [];
      acc.push(prompt);
      accumulator[prompt["category"]] = acc;
      return accumulator;
    }, {});
    setFilteredPrompts(reduced);
  };
  const handleShow = async () => {
    setShow(true);
    const response = await sendRequest(null, "prompts", "get");
    setRawPrompts(response.data);
    const reduced = response.data.reduce((accumulator, prompt) => {
      let acc = accumulator[prompt["category"]] ?? [];
      acc.push(prompt);
      accumulator[prompt["category"]] = acc;
      return accumulator;
    }, {});
    setFilteredPrompts(reduced);
  };
  const account = useAccount(accounts[0] || undefined);
  const signIn = () => {
    instance.loginPopup();
  };
  const signOut = () => {
    instance.logoutPopup();
    navigate("/");
  };

  return (
    <div className="unybrands-bg sticky top-0 p-2">
      <header className="mx-10 pt-4 lg:px-6 h-14 flex flex-none items-center shadow-sm ">
        <Link className="flex items-center " to="/home">
          <img src={Logo} alt="Logo" className="h-20 w-20 text-gray-900 " />
          <span className="text-xl font-bold px-2">|</span>
          <img
            src={AILogo}
            alt="Logo"
            className="h-8 w-8 text-gray-900 dark:text-gray-50 "
          />
          <p className="max-w-[600px] md:text-xl dark:text-gray-400">
            <span className="font-bold pl-1">Ai</span>Toolkit{" "}
          </p>
          <span></span>
        </Link>
        <nav className="ml-auto flex items-center gap-4 sm:gap-6">
          <div className="flex items-center gap-2">
            <AuthenticatedTemplate>
              <span className="px-5">
                Welcome, {account ? account.name : ""}
              </span>{" "}
              <Button onClick={() => navigate("/home")}>Back</Button>
              <Button onClick={() => signOut()}>Logout</Button>
              <Drawer direction="left" handleOnly>
                <DrawerTrigger asChild>
                  <Button onClick={() => handleShow()}>Prompt Bank</Button>
                </DrawerTrigger>
                <DrawerContent className="h-screen top-0 left-0 right-auto mt-0 w-[500px] rounded-none">
                  <ScrollArea className="h-screen p-4 ">
                    <Input
                      placeholder="Search"
                      className="focus-visible:ring-0"
                      onChange={onChange}
                    ></Input>
                    <PromptBank prompts={filteredPrompts} />
                  </ScrollArea>
                </DrawerContent>
              </Drawer>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <Button onClick={() => signIn()}>Login</Button>
            </UnauthenticatedTemplate>
          </div>
        </nav>
      </header>
    </div>
  );
}
