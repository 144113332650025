import { zodResolver } from "@hookform/resolvers/zod";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useForm } from "react-hook-form";
import {
  Form,
  FormField,
  FormControl,
  FormDescription,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { z } from "zod";
import { sendRequest } from "../services/apiService";
import Loader from "@/components/includes/Loader";

const formSchema = z.object({
  username: z.string().min(2, {
    message: "Username must be at least 2 characters.",
  }),
});

const CountryList = [
  {
    optionName: "United States",
    optionValue: "US",
  },
  {
    optionName: "United Kingdom",
    optionValue: "UK",
  },
  {
    optionName: "France",
    optionValue: "FR",
  },
  {
    optionName: "Germany",
    optionValue: "DE",
  },
  {
    optionName: "Italy",
    optionValue: "IT",
  },
  {
    optionName: "Australia",
    optionValue: "AU",
  },
  {
    optionName: "Belgium",
    optionValue: "BE",
  },
  {
    optionName: "Brazil",
    optionValue: "BR",
  },
  {
    optionName: "Canada",
    optionValue: "CA",
  },
  {
    optionName: "China",
    optionValue: "CN",
  },
  {
    optionName: "Egypt",
    optionValue: "EG",
  },
  {
    optionName: "India",
    optionValue: "IN",
  },
  {
    optionName: "Japan",
    optionValue: "JP",
  },
  {
    optionName: "Mexico",
    optionValue: "MX",
  },
  {
    optionName: "Netherlands",
    optionValue: "NL",
  },
  {
    optionName: "Poland",
    optionValue: "PL",
  },
  {
    optionName: "Saudi Arabia",
    optionValue: "SA",
  },
  {
    optionName: "Singapore",
    optionValue: "SG",
  },
  {
    optionName: "Spain",
    optionValue: "ES",
  },
  {
    optionName: "Sweden",
    optionValue: "SE",
  },
  {
    optionName: "Turkey",
    optionValue: "TR",
  },
  {
    optionName: "UAE",
    optionValue: "AE",
  },
];
const NUMBER_OF_SUGGESTIONS = 5;
const NewFeatureBullet = () => {
  const navigate = useNavigate();

  const [showLoader, setShowLoader] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [requestData, setRequestData] = useState({});
  const [requestDone, setRequestDone] = useState(false);
  const [featuredBullets, setFeatureBullets] = useState([]);
  const [num_reviews, setNumberOfReviews] = useState();
  const [thread_id, setThreadId] = useState("");
  const [title, setTitle] = useState("");
  const suggestions_list = [...Array(NUMBER_OF_SUGGESTIONS + 1).keys()]
    .slice(1)
    .map((i) => {
      return { optionName: `${i}`, optionValue: `${i}` };
    });
  const {
    register,
    handleSubmit,
    setError,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({ mode: "onChange" });

  const onSubmit = async (data) => {
    setShowLoader(true);

    const requestBody = Object.fromEntries(
      Object.entries(data).filter(([key, value]) => value !== "")
    );
    const response = await sendRequest(requestBody, "generate_feature_bullets");

    if (response.status === 200) {
      setFeatureBullets(response.data.feature_bullets);
      setSuggestions(response.data.suggestions);
      setNumberOfReviews(response.data.review_list.length);
      setTitle(response.data.product_title);
      setShowLoader(false);
      setRequestDone(true);
      setThreadId(response.data.thread_id);
      const heading = `### **Feature Bullets**`;
      const asinString = `### **Asin**: ${requestBody.asin}`;
      const titleString = `**Title**: ${response.data.product_title}`;
      const numOfReviewsString = `**Number of reviews**: ${response.data.review_list.length}`;
      const featureBulletsString = response.data.feature_bullets.join("\n");
      const suggestionsString = response.data.suggestions.join("\n");
      const thread_id = response.data.thread_id;
      const finalString = [
        heading,
        asinString,
        titleString,
        numOfReviewsString,
        featureBulletsString,
        suggestionsString,
      ].join("\n");
      navigate("/results", {
        state: { data: finalString, thread_id: thread_id },
      });
    }

    setShowLoader(false);
    setRequestDone(false);
    setError("asin", { type: "custom", message: "ASIN not found" });

    // }
  };
  return (
    <div>
      <h1 className="flex text-3xl font-bold tracking-tighter sm:text-5xl xl:text-4xl/none justify-center pb-4">
        Feature Bullet Generator
      </h1>
      <div className="flex flex-grow overflow-auto p-4 space-y-10 justify-center">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className=" flex-col w-[400px] space-y-5">
            <div className="space-y-2 ">
              <Input
                {...register("asin", {
                  required: {
                    value: true,
                    message: "Please fill in the ASIN field",
                  },
                  minLength: {
                    value: 10,
                    message: "Please enter a 10-digit ASIN",
                  },
                  maxLength: {
                    value: 10,
                    message: "Please enter a 10-digit ASIN",
                  },
                })}
                Label="Enter ASIN #(Required):"
                placeholder="Enter the exact Amazon ASIN"
                errors={errors}
                inputId="asin"
              />
              {errors.asin && (
                <span className="text-destructive text-xs">
                  {errors.asin.message}
                </span>
              )}
            </div>
            <div className="space-y-2">
              <Select
                defaultValue="US"
                inputId="amazon_domain"
                name="test"
                onValueChange={(e) => setValue("amazon_domain", e)}
              >
                <SelectTrigger>
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {CountryList.map((country) => (
                      <SelectItem value={country.optionValue}>
                        {country.optionName}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            <div className="space-y-5">
              <Input
                id="email"
                placeholder="Prompt instructions"
                {...register("prompt")}
              />
            </div>
            <div className="gap-y-2">
              <Input
                id="email"
                placeholder="Product features"
                {...register("product_features")}
              />
            </div>
            <div className="gap-y-2">
              <Input
                id="email"
                placeholder="Product benefits"
                {...register("product_benefits")}
              />
            </div>
            <div className="gap-y-2">
              <Input id="email" placeholder="Tone" {...register("tone")} />
            </div>
            <div className="space-y-2">
              <Textarea
                id="email"
                placeholder="Keywords"
                {...register("updated_keyword_list")}
              />
              <Label htmlFor="email" className="text-xs text-muted-foreground">
                Add keywords here. If using Data Dive, you should copy and paste
                the contents of the 'phrase' and 'ranking juice' columns into
                here. It should look as follows:
                <br />
                Keyword1 100
                <br />
                Keyword2 200
                <br />
                Keyword3 300
              </Label>
            </div>
            <div className="space-y-2">
              <Select
                defaultValue={"1"}
                onValueChange={(e) => setValue("num_suggestions", e)}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Number of suggestions" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {suggestions_list.map((item) => (
                      <SelectItem value={item.optionValue}>
                        {item.optionName}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            <div>
              <Button className="w-full">Submit</Button>
            </div>
          </div>
        </form>
      </div>
      <Loader showLoader={showLoader} />
    </div>
  );
};

export default NewFeatureBullet;
