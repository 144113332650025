import React, {useState} from "react";
import loaderImg from "../assets/images/loader.gif";
import Footertext from "../components/includes/FooterText";
import Loader from "../components/includes/Loader";
import CopyButton from "../components/includes/CopyButton";
import RegenerateButton from "../components/includes/RegenrateButton";

const ImageResults = ({suggestions, regenerateFunction}) => {
    // Check if suggestions array is undefined
    if (!suggestions) {
        return <div>No suggestions available</div>;
    }
    return (
        <>
            <div className="container-xxl main__wrapper" id="results_data">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="mb-5">
                            <div className="row mb-4">
                                <div className="col-sm-6">
                                    <h2 className="page__head">Image</h2>
                                </div>
                                <div className="col-sm-6 position-relative d-flex justify-content-end">
                                    <CopyButton/>
                                    <p id="copyMessage" className="copy_pop"></p>
                                    <RegenerateButton regenerateFunction={regenerateFunction}/>
                                </div>
                            </div>
                            <div className="suggestion p-4" id="contentToCopy">
                                <div className="row mb-5 justify-content-center">
                                    {suggestions.map((suggest) => (
                                        <div className="col-xl-3 col-lg-4 col-sm-6 col-12 mb-sm-0 mb-4">
                                            <h3>
                                                <img src={suggest} alt="the generated image"/>
                                            </h3>
                                        </div>
                                    ))
                                    }
                                </div>
                            </div>
                        </div>

                        <Footertext/>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ImageResults;
