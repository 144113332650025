export default function Input({
  Label,
  additionalClass,
  type,
  placeholder,
  inputProps,
  errors,
  inputId,
  defaultValue,
  children,
}) {
  return (
    <div className="mb-4 position-relative">
      {Label && (
        <label htmlFor={inputId} className={`form-label mb-3`}>
          {Label}
        </label>
      )}
      <div className="position-relative d-block">
        <input
          {...inputProps}
          id={inputId}
          type={type ? type : "text"}
          className={`form-control p-3 ${additionalClass}`}
          placeholder={placeholder ? placeholder : "Please Enter"}
          defaultValue={defaultValue}
        />
        {errors?.[inputProps.name] && (
          <span className="required_pop">
            {errors[inputProps.name].message}
          </span>
        )}
      </div>
      {children}
    </div>
  );
}
