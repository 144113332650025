import { sendRequest } from "../services/apiService";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Input from "../components/core/input";
import { useForm, reset } from "react-hook-form";
import TextArea from "../components/core/textArea";

const Crud = () => {
  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({ mode: "onChange" });
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [data, setData] = useState([]);
  useEffect(() => {
    const setResponse = async () => {
      const response = await sendRequest(null, "prompts", "get");
      setData(response.data);
    };
    setResponse();
  }, []);
  const onDelete = async (id) => {
    const response = await sendRequest({ prompt_id: id }, "prompts", "delete");
  };
  const onSubmit = async (data) => {
    const response = await sendRequest(data, "prompts", "post");
    if (response.status == 200) {
      handleClose();
    }
  };
  const onUpdate = () => {};
  return (
    <div className="m-5">
      <Button
        className="btn btn__outline btn_logout border-0 mb-2"
        onClick={handleShow}
      >
        Create
      </Button>
      <table className="table table-light">
        <thead className="">
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Title</th>
            <th scope="col">Prompt</th>
            <th scope="col">Category</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((prompt) => {
              return (
                <tr>
                  <th scope="row">{prompt["id"]}</th>
                  <td>{prompt["title"]}</td>
                  <td>{prompt["prompt"]}</td>
                  <td>{prompt["category"]}</td>
                  <td>
                    <Button
                      className="btn btn__outline btn_logout border-0"
                      onClick={() => onDelete(prompt.id)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-dark">New Prompt</Modal.Title>
        </Modal.Header>
        <form
          className="form__container mb-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Modal.Body className="text-dark">
            <Input
              inputProps={register("title")}
              Label="Title"
              placeholder="Enter title"
              errors={errors}
              inputId="title"
            />
            <TextArea
              inputProps={register("prompt")}
              Label="Prompt"
              placeholder="Enter Prompt"
              errors={errors}
              inputId="prompt"
            />
            <Input
              inputProps={register("category")}
              Label="Category"
              placeholder="Enter Category"
              errors={errors}
              inputId="category"
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              disabled={isSubmitting}
              label="Submit"
              type="submit"
              additionalClass="mt-3"
            >
              Submit
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default Crud;
