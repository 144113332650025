import LoaderGif from "../../assets/images/loader.gif";

export default function Loader({ loaderImg = LoaderGif, showLoader }) {
  return (
    <div>
      <div
        class={`absolute right-1/2 bottom-1/2  transform translate-x-1/2 translate-y-1/2 ${
          !showLoader ? "hidden" : ""
        }`}
      >
        <div class="border-t-transparent border-solid animate-spin  rounded-full border-blue-400 border-8 h-64 w-64"></div>
      </div>
    </div>
  );
}
