import React, {useState} from "react";
import loaderImg from "../assets/images/loader.gif";
import Footertext from "../components/includes/FooterText";
import Loader from "../components/includes/Loader";
import FormTitle from "../components/includes/FormTitle";
import TextArea from "../components/core/textArea";
import {useForm} from "react-hook-form";
import Input from "../components/core/input";
import Button from "../components/core/button";
import {sendRequest} from "../services/apiService";
import ProductDescResult from "./ProductDescResult";
import ImageResults from "./ImageResults";

const ImageGenerator = () => {
    const [showLoader, setShowLoader] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [requestData, setRequestData] = useState({});
    const [requestDone, setRequestDone] = useState(false);

    const {
        register,
        handleSubmit,
        formState: {errors, isSubmitting},
    } = useForm({mode: "onChange"});

    const onSubmit = async (data) => {
        setShowLoader(true);

        const requestBody = Object.fromEntries(
            Object.entries(data).filter(([key, value]) => value !== "")
        );

        const response = await sendRequest(requestBody, "generate_image")

        if (response.status === 200) {
            console.log(response)
            setSuggestions(response.data.suggestions);
            setRequestData(requestBody);
            setShowLoader(false);
            setRequestDone(true);
            return
        }


        setShowLoader(false);
        setRequestDone(false);
        alert('Error generating feature bullets')

    };

    return (
        <>
            <div
                className={`container-xxl main__wrapper ${(!showLoader && !requestDone) ? '' : 'd-none'} `}
                id="generator_form_container"
            >
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6 col-sm-8">
                        <FormTitle
                            title="Product Image Generator with DALL E 3"
                            subTitle="Fill the necessary field to proceed"
                        />
                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            className="form__container mb-4"
                        >
                            <TextArea
                                inputProps={register("prompt", {
                                    required: {
                                        value: true,
                                        message: "Please add prompt here",
                                    },
                                })}
                                TextAreaId="prompt"
                                Label="Prompt/Instructions:"
                                placeholder="Provide additional instructions"
                                errors={errors}
                            >
                                <p className="mt-3 mb-0" style={{fontSize: "12px"}}>
                                    Example Prompt: Generate a high-resolution, professional, and
                                    appealing product image of
                                    <span id="product-description">
                    [Product Description Here] for the brand [brand name]
                  </span>
                                    . The image should be suitable. The product should be
                                    well-lit, with clear details, no background distractions, and
                                    a white or transparent background.
                                </p>
                            </TextArea>
                            <Input
                                inputProps={register("num_suggestions")}
                                Label="Number of Suggestions (optional):"
                                placeholder="Number of Suggestions. Use an Arabic numeral. (optional) (Default is 1)"
                                errors={errors}
                                inputId="num_suggestions"
                                defaultValue="1"
                            >
                                <div className="form-text mt-3">
                                    Number of Suggestions. You can ask for maximum of 5
                                    suggestions.(optional) (Default is 1)
                                </div>
                            </Input>
                            <Button disabled={isSubmitting} label="Submit" type="submit"/>
                        </form>
                        <Footertext/>
                    </div>
                </div>
            </div>
            {
                (suggestions.length > 0 && !showLoader) && (
                    <ImageResults
                        regenerateFunction={()=>onSubmit(requestData)}
                        suggestions={suggestions}/>
                )
            }
            <Loader
                showLoader={showLoader}
                setShowLoader={setShowLoader}
                loaderImg={loaderImg}
            />
        </>
    );
};

export default ImageGenerator;
