import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Button } from "../ui/button";
const PromptBank = ({ prompts }) => {
  const entries = Object.entries(prompts);
  const copyText = (text) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <div key={"key"}>
      <Accordion collapsible={true} className="w-full">
        {entries.map((prompt, i) => {
          return (
            <AccordionItem value={`-item-${i}`}>
              <AccordionTrigger>{prompt[0]}</AccordionTrigger>
              <AccordionContent>
                <Accordion type="single" collapsible className="p-4">
                  {prompt[1].map((p, j) => {
                    return (
                      <AccordionItem value={`inner-item-${j}`}>
                        <AccordionTrigger>
                          <p>{p.title}</p>
                        </AccordionTrigger>
                        <AccordionContent>
                          <p
                            className="hover:bg-slate-800 rounded-lg p-4 active:bg-slate-600"
                            onClick={() => copyText(p.prompt)}
                          >
                            {p.prompt}
                          </p>
                        </AccordionContent>
                      </AccordionItem>
                    );
                  })}
                </Accordion>
              </AccordionContent>
            </AccordionItem>
          );
        })}
      </Accordion>
    </div>
  );
};

export default PromptBank;
