import { columns } from "@/components/column";
import { DataTable } from "@/components/datatable";
import { useEffect, useState } from "react";
import { sendRequest } from "../services/apiService";
import { Plus } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useForm, reset } from "react-hook-form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";

export default function NewCrud() {
  const [show, setShow] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({ mode: "onChange" });
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [data, setData] = useState([]);
  useEffect(() => {
    const setResponse = async () => {
      const response = await sendRequest(null, "prompts", "get");
      setData(response.data);
    };
    setResponse();
  }, []);
  const onDelete = async (id) => {
    const response = await sendRequest({ prompt_id: id }, "prompts", "delete");
    const newResponse = await sendRequest(null, "prompts", "get");
    setData(newResponse.data);
  };
  const onSubmit = async (data) => {
    const response = await sendRequest(data, "prompts", "post");
    if (response.status == 200) {
      setShow(false);
      const response = await sendRequest(null, "prompts", "get");
      setData(response.data);
    }
  };

  return (
    <div className="container mx-auto py-10 overflow-auto">
      <Dialog open={show} onOpenChange={setShow}>
        <DialogTrigger>
          {" "}
          <Plus className="rounded w-10 h-10 m-2 hover:bg-slate-800 p-2" />
        </DialogTrigger>
        <DialogContent className="space-6">
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogHeader className="my-2">
              <DialogTitle className="my-2">New Prompt</DialogTitle>
              <DialogDescription className="flex flex-col space-y-6">
                <Input
                  {...register("title")}
                  Label="Title"
                  placeholder="Enter title"
                  errors={errors}
                  inputId="title"
                />
                <Textarea
                  {...register("prompt")}
                  Label="Prompt"
                  placeholder="Enter Prompt"
                  errors={errors}
                  inputId="prompt"
                />
                <Input
                  {...register("category")}
                  Label="Category"
                  placeholder="Enter Category"
                  errors={errors}
                  inputId="category"
                />
              </DialogDescription>
            </DialogHeader>
            <DialogFooter className="flex space-x-5">
              <Button disabled={isSubmitting} label="Submit" type="submit">
                Submit
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
      <DataTable
        columns={columns}
        data={data}
        onDelete={onDelete}
        onSubmit={onSubmit}
      />
    </div>
  );
}
