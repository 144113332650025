import React, { useEffect, useState } from "react";
import loaderImg from "../assets/images/loader.gif";
import Footertext from "../components/includes/FooterText";
import Loader from "../components/includes/Loader";
import FormTitle from "../components/includes/FormTitle";
import { useForm } from "react-hook-form";
import Input from "../components/core/input";
import Select from "../components/core/Select";
import TextArea from "../components/core/textArea";
import Button from "../components/core/button";
import { sendRequest } from "../services/apiService";
import ProductDescResult from "./ProductDescResult";

const CountryList = [
  {
    optionName: "United States",
    optionValue: "US",
  },
  {
    optionName: "United Kingdom",
    optionValue: "UK",
  },
  {
    optionName: "France",
    optionValue: "FR",
  },
  {
    optionName: "Germany",
    optionValue: "DE",
  },
  {
    optionName: "Italy",
    optionValue: "IT",
  },
  {
    optionName: "Australia",
    optionValue: "AU",
  },
  {
    optionName: "Belgium",
    optionValue: "BE",
  },
  {
    optionName: "Brazil",
    optionValue: "BR",
  },
  {
    optionName: "Canada",
    optionValue: "CA",
  },
  {
    optionName: "China",
    optionValue: "CN",
  },
  {
    optionName: "Egypt",
    optionValue: "EG",
  },
  {
    optionName: "India",
    optionValue: "IN",
  },
  {
    optionName: "Japan",
    optionValue: "JP",
  },
  {
    optionName: "Mexico",
    optionValue: "MX",
  },
  {
    optionName: "Netherlands",
    optionValue: "NL",
  },
  {
    optionName: "Poland",
    optionValue: "PL",
  },
  {
    optionName: "Saudi Arabia",
    optionValue: "SA",
  },
  {
    optionName: "Singapore",
    optionValue: "SG",
  },
  {
    optionName: "Spain",
    optionValue: "ES",
  },
  {
    optionName: "Sweden",
    optionValue: "SE",
  },
  {
    optionName: "Turkey",
    optionValue: "TR",
  },
  {
    optionName: "UAE",
    optionValue: "AE",
  },
];
const NUMBER_OF_SUGGESTIONS = 5;
// This component should be refactored into a wrapper component
// and change the title as a parameter it shares logic with FeatureBulletGenerator
const TitleGenerator = () => {
  // move this logic to a zustand state
  const [showLoader, setShowLoader] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [apiResponse, setResponse] = useState({});
  const [requestData, setRequestData] = useState({});
  const [requestDone, setRequestDone] = useState(false);
  const [featuredBullets, setFeatureBullets] = useState([]);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, reset, isSubmitting },
  } = useForm({ mode: "onChange" });

  const suggestions_list = [...Array(NUMBER_OF_SUGGESTIONS + 1).keys()]
    .slice(1)
    .map((i) => {
      return { optionName: i, optionValue: i };
    });

  const onSubmit = async (data) => {
    setShowLoader(true);

    const requestBody = Object.fromEntries(
      Object.entries(data).filter(([key, value]) => value !== "")
    );

    const response = await sendRequest(requestBody, "generate_title");

    if (response.status === 200) {
      setFeatureBullets(response.data.feature_bullets);
      setResponse(response.data);
      setSuggestions(response.data.suggestions);
      setRequestData(requestBody);
      setShowLoader(false);
      setRequestDone(true);
      return;
    }

    setShowLoader(false);
    setRequestDone(false);
    setError("asin", { type: "custom", message: "ASIN not found" });
  };

  return (
    <>
      <div
        className={`container-xxl main__wrapper ${
          !showLoader && !requestDone ? "" : "d-none"
        } `}
        id="generator_form_container"
      >
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 col-sm-8">
            <FormTitle
              title="Product Listing Title Generator"
              subTitle="Fill the necessary field to proceed"
            />
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="form__container mb-4"
            >
              <Input
                inputProps={register("asin", {
                  required: {
                    value: true,
                    message: "Please fill in the ASIN field",
                  },
                  minLength: {
                    value: 10,
                    message: "Please enter a 10-digit ASIN",
                  },
                  maxLength: {
                    value: 10,
                    message: "Please enter a 10-digit ASIN",
                  },
                })}
                Label="Enter ASIN #(Required):"
                placeholder="Enter the exact Amazon ASIN"
                errors={errors}
                inputId="asin"
              />
              <div className="position-relative">
                <Select
                  SelectData={CountryList}
                  label="Country (Amazon Domain):"
                  selectId="amazon_domain"
                  inputProps={register("amazon_domain", {
                    required: {
                      value: true,
                      message: "Please select a country",
                    },
                  })}
                />
                {errors.country && (
                  <span className="required_pop" style={{ top: "-10px" }}>
                    Please select a country
                  </span>
                )}
              </div>
              <Input
                inputProps={register("prompt")}
                Label="Prompt/Instructions (optional):"
                placeholder="Provide additional instructions"
                errors={errors}
                inputId="prompt"
              />
              <Input
                inputProps={register("product_features")}
                Label="Product Features (optional):"
                placeholder="Provide additional instructions"
                errors={errors}
                inputId="product_features"
              />
              <Input
                inputProps={register("product_benefits")}
                Label="Product Benefits (optional):"
                placeholder="Add specific product benefits(optional)"
                errors={errors}
                inputId="product_benefits"
              />
              <Input
                inputProps={register("tone")}
                Label="Tone (optional):"
                placeholder="Add specific tone (e.g. 'professional' or 'same as current)"
                errors={errors}
                inputId="tone"
              />
              <TextArea
                inputProps={register("updated_keyword_list", {
                  required: {
                    value: true,
                    message: "Please update the keyword list",
                  },
                })}
                TextAreaId="updated_keyword_list"
                Label="Keyword List:"
                placeholder="Add specific keywords"
                errors={errors}
              >
                <div className="form-text mt-3">
                  {" "}
                  Add keywords here. If using Data Dive, you should copy and
                  paste the contents of the 'phrase' and 'ranking juice' columns
                  into here. It should look as follows:
                  <br />
                  Keyword1 100
                  <br />
                  Keyword2 200
                  <br />
                  Keyword3 300
                </div>
              </TextArea>
              <Select
                SelectData={suggestions_list}
                label="Number of suggestions"
                selectId="amazon_domain"
                inputProps={register("num_suggestions")}
              />
              <Button disabled={isSubmitting} label="Submit" type="submit" />
            </form>
            <Footertext />
          </div>
        </div>
      </div>
      {featuredBullets.length > 0 && suggestions.length > 0 && !showLoader && (
        <ProductDescResult
          heading={"Product Title"}
          asin={requestData.asin}
          thread_id={apiResponse.thread_id}
          regenerateFunction={() => onSubmit(requestData)}
          feature_bullets={[apiResponse.product_title]}
          suggestions={suggestions}
          title={apiResponse.product_title}
        />
      )}
      <Loader
        showLoader={showLoader}
        setShowLoader={setShowLoader}
        loaderImg={loaderImg}
      />
    </>
  );
};

export default TitleGenerator;
