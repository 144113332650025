import React, { useState } from "react";
import Footertext from "../components/includes/FooterText";
import DOMPurify from "dompurify";
import CopyButton from "../components/includes/CopyButton";
import RegenerateButton from "../components/includes/RegenrateButton";
import ChatBox from "../components/core/chatbox";
import Input from "../components/core/input";
import { useForm } from "react-hook-form";
import { sendRequest } from "../services/apiService";
import { toast, ToastContainer } from "react-toastify";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Constants } from "@/constants";

const ProductDescResult = ({
  heading,
  asin,
  title,
  num_reviews,
  suggestions,
  feature_bullets,
  regenerateFunction,
  thread_id,
}) => {
  const [messages, setMessages] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [copyMessage, setCopyMessage] = useState("");

  const handleCopyClick = () => {
    const contentDiv = document.getElementById("contentToCopy");
    const content = contentDiv.textContent;

    const textarea = document.createElement("textarea");
    textarea.value = content;
    document.body.appendChild(textarea);

    textarea.select();
    document.execCommand("copy");

    document.body.removeChild(textarea);

    setCopyMessage("Text has been copied");
    setTimeout(() => {
      setCopyMessage("");
    }, 1500);
  };

  if (!suggestions) {
    return <div>No suggestions available</div>;
  }
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({ mode: "onChange" });
  const onSubmit = async (data, e) => {
    e.target.reset();
    const messageWithSender = { sender: "user", message: data.message };
    setShowLoader(true);
    setMessages(() => [...messages, messageWithSender]);
    const params = { thread_id: thread_id, message: data.message };
    const resp = await sendRequest(params, "chat");
    if (resp.status == 500) {
      toast.error(`Something went wrong: Error ${resp.status}`, {
        position: "top-center",
      });
      setShowLoader(false);

      return;
    }
    let attachments_list = [];
    let content = [];
    let images = [];
    if ("attachments" in resp.data) {
      resp.data.content.map((c) => {
        if (c.type == "text") {
          content.push(c.text.value);
        } else if (c.type == "image_file") {
          images.push(
            <img
              src={`${Constants.apiUrl}/files/${c.image_file.file_id}?type=image`}
              style={{ width: "500px" }}
            />
          );
        }
      });

      resp.data.attachments.forEach((attachment) => {
        attachments_list.push(
          <a href={`${Constants.apiUrl}/files/${attachment.file_id}`}>
            Download file here
          </a>
        );
      });
    } else {
      content.push(JSON.stringify(resp.data));
    }
    setShowLoader(false);
    setMessages([
      ...messages,
      messageWithSender,
      {
        sender: "ai",
        message: (
          <div>
            <div>{images.map((e) => e)}</div>
            <Markdown remarkPlugins={[remarkGfm]}>
              {content.map((e) => e).join("")}
            </Markdown>
            <div>{attachments_list.map((e) => e)}</div>
          </div>
        ),
      },
    ]);
  };
  return (
    <>
      <ToastContainer />
      <div className="container-xxl main__wrapper" id="results_data">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="mb-5">
              <div className="row mb-4">
                <div className="col-sm-6">
                  <h2 className="page__head">New {heading}</h2>
                  <h6>
                    {" "}
                    <b>Asin: </b>
                    {asin} <br />
                    <b>Title:</b>
                    {title} <br />
                    {num_reviews && (
                      <>
                        <b>Number Reviews:</b> {num_reviews}{" "}
                      </>
                    )}
                  </h6>
                </div>
                <div className="col-sm-6 position-relative d-flex justify-content-end">
                  <CopyButton clickFunction={handleCopyClick} />
                  <p id="copyMessage" className="copy_pop">
                    {copyMessage}
                  </p>
                  <RegenerateButton regenerateFunction={regenerateFunction} />
                </div>
              </div>
              <div className="suggestion p-4" id="contentToCopy">
                {suggestions.map((suggestion, index) => (
                  <React.Fragment key={index}>
                    {suggestion.startsWith("Suggestion 1") ||
                    suggestion.startsWith("Suggestion") ? (
                      <h3>{suggestion}</h3>
                    ) : suggestion.startsWith("•") ? (
                      <>
                        {suggestion}
                        <br />
                      </>
                    ) : (
                      <p>{suggestion}</p>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
            <ChatBox messages={messages} showLoader={showLoader} />
            <div>
              <h2 className="page__head mb-4">Old {heading}</h2>
              <div className="review_data p-4 mb-5">
                {feature_bullets &&
                  Object.values(feature_bullets).map((bullet, index) => (
                    <div
                      key={index}
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(bullet),
                      }}
                    />
                  ))}
              </div>
            </div>
            <Footertext />
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{
                position: "fixed",
                bottom: 0,
                width: "87%",
                marginBottom: "50px",
              }}
            >
              <Input
                inputProps={register("message")}
                placeholder="Chat here..."
                errors={errors}
                inputId="message"
              />
            </form>
          </div>
        </div>
      </div>
      {/*<Loader*/}
      {/*    showLoader={showLoader}*/}
      {/*    setShowLoader={setShowLoader}*/}
      {/*    loaderImg={loaderImg}*/}
      {/*/>*/}
    </>
  );
};

export default ProductDescResult;
