import { CopyIcon } from "../iconset";

export default function CopyButton({clickFunction}) {
  return (
    <button
      id="copyButton"
      className="btn__text btn__copy me-1"
      onClick={clickFunction}
    >
      <CopyIcon />
      Copy
    </button>
  );
}
