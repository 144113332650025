import { RegenerateIcon } from "../iconset";

export default function RegenerateButton({regenerateFunction}) {
  return (
    <button id="refreshButton" className="btn__text" onClick={regenerateFunction}>
      <RegenerateIcon />
      Regenerate
    </button>
  );
}
