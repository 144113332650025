import { Constants } from "@/constants";

export const msalConfig = {
  auth: {
    clientId: Constants.clientId,
    authority: Constants.authority,
    redirectUri: Constants.redirectUri,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: ["User.Read"],
};
