import Spinner from "react-bootstrap/Spinner";
import { cn } from "@/lib/utils";

import { Loader2 } from "lucide-react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { useEffect, useRef } from "react";

const Loader = ({ className }) => {
  return (
    <Loader2
      className={cn(" h-16 w-16 text-primary/60 animate-spin", className)}
    />
  );
};
const CustomImage = ({ src, alt, width }) => {
  return <img src={src} alt={alt} style={{ width: width || "100%" }} />;
};

export default function ChatBox({ messages, showLoader, current_message }) {
  const ref = useRef();

  const scrollToBottom = () => {
    ref.current.scrollTop = ref.current.scrollHeight;
  };

  useEffect(() => {
    scrollToBottom();
  }, [current_message, messages, showLoader]);

  return (
    <div className="flex-1 overflow-y-auto" id="assistantChat" ref={ref}>
      {messages.map((message) => {
        if (message.sender === "user") {
          return (
            <div className="flex flex-row-reverse" key={message.timestamp}>
              <span className="bg-slate-600 p-3 rounded-xl">
                <div>
                  <Markdown
                    remarkPlugins={[remarkGfm]}
                    components={{
                      img: ({ node, ...props }) => (
                        <CustomImage {...props} width="400px" />
                      ),
                    }}
                  >
                    {message.message}
                  </Markdown>
                </div>
              </span>
            </div>
          );
        }

        return (
          <div key={message.timestamp}>
            <div>
              <Markdown
                remarkPlugins={[remarkGfm]}
                components={{
                  img: ({ node, ...props }) => (
                    <CustomImage {...props} width="400px" />
                  ),
                }}
              >
                {message.message}
              </Markdown>
            </div>
          </div>
        );
      })}
      {showLoader && current_message.length === 0 && (
        <div className="p-4">
          <Loader className="w-10 h-10" />
        </div>
      )}
      {current_message.length > 0 && (
        <div>
          <Markdown remarkPlugins={[remarkGfm]}>{current_message}</Markdown>
        </div>
      )}
    </div>
  );
}
