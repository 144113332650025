import { Link } from "react-router-dom";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Input } from "@/components/ui/input";

import { useForm } from "react-hook-form";
import { sendRequest } from "../services/apiService";
import { toast, ToastContainer } from "react-toastify";

import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useIsAuthenticated } from "@azure/msal-react";
import ChatBox from "@/components/core/chatbox";
import { Pause, StopCircle } from "lucide-react";
import { Constants } from "@/constants";

export default function Home() {
  const [showLoader, setShowLoader] = useState(false);
  const formRef = useRef();
  const isAuthenticated = useIsAuthenticated();
  let location = useLocation();
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [thread_id, setThreadId] = useState("");
  var aiMessage = "";
  async function getThreadId() {
    const res = await sendRequest(
      null,
      `thread_id?bot_name=${location.state.bot_name}`,
      "get"
    );
    setThreadId(res.data.thread_id);
  }
  function getText(arr) {
    for (const i in arr) {
      let result = JSON.parse(arr[i], { stream: true });
      if (result.type === "text") {
        if (result.text.value) {
          aiMessage += result.text.value;
          setMessage(aiMessage);
        } else {
          for (const key in result.text.annotations) {
            const element = result.text.annotations[key];
            if (element.type == "file_path") {
              aiMessage = aiMessage.replace(
                element.text,
                `${Constants.apiUrl}/files/${element.file_path.file_id}`
              );
              setMessage(aiMessage);
            }
          }
        }
      } else if (result.type === "image_file") {
        aiMessage += `![Image](${Constants.apiUrl}/files/${result.image_file.file_id}?type=image)`;
        setMessage(aiMessage);
      }
    }
  }
  useEffect(() => {
    if (location.state.thread_id) {
      setThreadId(location.state.thread_id);
    } else {
      getThreadId();
    }
    const finalString = location.state.data;
    if (finalString) {
      setMessages([
        {
          sender: "ai",
          message: finalString,
        },
      ]);
    }
  }, []);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({ mode: "onChange" });
  const [files, setFiles] = useState([]);
  const onFileChange = (e) => {
    const filesArray = Array.from(e.target.files || []);
    setFiles(filesArray);
  };
  const onTextareaKeydown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      formRef.current?.requestSubmit();
      e.target.style.height = "";
    }
  };
  const CustomImage = ({ src, alt, width }) => {
    return <img src={src} alt={alt} style={{ width: width || "100%" }} />;
  };
  const onPaste = (e) => {
    if (e.target.value == "") {
      e.preventDefault();
      e.target.value = e.clipboardData.getData("Text");
      e.target.setSelectionRange(0, 0);
    }
    e.target.style.height = "auto";
    e.target.style.height = e.target.scrollHeight + "px";
  };
  const onSubmit = async (data, e) => {
    e.target.reset();
    setMessage(() => "");
    const messageWithSender = {
      sender: "user",
      message: data.message,
      timestamp: new Date().toISOString(), // Add timestamp here
    };
    setShowLoader(true);
    setMessages((prev) => [...prev, messageWithSender]);
    let params = { message: data.message, bot_name: location.state.bot_name };
    if (files.length > 0) {
      const file = new FormData();
      files.forEach((fileItem) => {
        file.append("files", fileItem, fileItem.name); // Append each file with its name
      });
      const resp = await sendRequest(
        file,
        "files",
        "post",
        "multipart/form-data"
      );
      params["file_ids"] = resp.data.file_ids;
    }
    try {
      const response = await fetch(`${Constants.apiUrl}/bot`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...params,
          thread: thread_id,
        }),
      });

      const reader = response.body.getReader();

      const decoder = new TextDecoder("utf-8");

      while (true) {
        const { done, value } = await reader.read();

        if (done) break;
        const val = decoder.decode(value, { stream: true });
        const arr = val.split("\n--END--\n").filter((ele) => ele);
        getText(arr);
      }
      setShowLoader(false);
      setMessages((prev) => [
        ...prev,
        {
          sender: "ai",
          message: aiMessage,
          timestamp: new Date().toISOString(),
        },
      ]);
      setMessage("");
    } catch {
      setMessages((prev) => [
        ...prev,
        {
          sender: "ai",
          message: aiMessage,
          timestamp: new Date().toISOString(),
        },
      ]);
      setShowLoader(false);
    }
  };
  async function onCancel() {
    await sendRequest(null, `cancel?thread_id=${thread_id}`, "get");
  }
  return (
    <div>
      <div class="w-full h-32"></div>
      <div class="mx-10 " style={{ "margin-top": "-128px" }}>
        <div class="py-6 h-screen">
          <div class="flex border border-grey rounded shadow-lg h-full">
            <div class="w-full border flex flex-col">
              <div class="flex-1 overflow-auto unybrands-bg-light">
                <div class="flex-1 overflow-auto unybrands-bg-light p-4">
                  <ChatBox
                    messages={messages}
                    showLoader={showLoader}
                    current_message={message}
                  />
                </div>
              </div>

              <div class="bg-grey-lighter px-4 py-4 w-full">
                <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
                  <div className="flex flex-row items-center gap-2">
                    <Textarea
                      disabled={showLoader}
                      {...register("message", {
                        required: {
                          value: true,
                        },
                      })}
                      onKeyDown={onTextareaKeydown}
                      onPaste={onPaste}
                      onInput={(e) => {
                        e.target.style.height = "auto";
                        e.target.style.height = e.target.scrollHeight + "px";
                      }}
                      Label="Chat here"
                      className="resize-none focus-visible:ring-0 "
                      placeholder="Message to AI Assistant"
                      errors={errors}
                      inputId="message"
                      style={{ maxHeight: "150px" }} // Limits the max height for readability
                    />
                    {!showLoader && (
                      <Button
                        className="top-10 right-10 w-8 h-8"
                        disabled={showLoader}
                        size="icon"
                      >
                        <ArrowUpIcon className="w-5 h-5" />
                        <span className="sr-only">Send</span>
                      </Button>
                    )}
                    {showLoader && (
                      <Button
                        onClick={() => onCancel()}
                        className="top-10 right-10 w-8 h-8"
                        disabled={!showLoader}
                        size="icon"
                      >
                        <Pause className="w-5 h-5" />
                        <span className="sr-only">Stop</span>
                      </Button>
                    )}
                  </div>
                  {errors.asin && (
                    <span className="text-destructive text-xs">
                      {errors.asin.message}
                    </span>
                  )}
                  <Input
                    multiple
                    {...register("files")}
                    className="text-sm text-grey-500 w-full h-24
              file:mr-5 file:py-2 file:px-6
              file:rounded-full file:border-0
              file:text-sm file:font-medium
              file:bg-blue-50 file:text-blue-700
              hover:file:cursor-pointer hover:file:bg-amber-50
              border-none
              hover:file:text-amber-700"
                    name="file"
                    type="file"
                    placeholder={"TEXT"}
                    onChange={onFileChange}
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ArrowUpIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m5 12 7-7 7 7" />
      <path d="M12 19V5" />
    </svg>
  );
}

function BotIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M12 8V4H8" />
      <rect width="16" height="12" x="4" y="8" rx="2" />
      <path d="M2 14h2" />
      <path d="M20 14h2" />
      <path d="M15 13v2" />
      <path d="M9 13v2" />
    </svg>
  );
}

function CodeIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polyline points="16 18 22 12 16 6" />
      <polyline points="8 6 2 12 8 18" />
    </svg>
  );
}

function ImageIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect width="18" height="18" x="3" y="3" rx="2" ry="2" />
      <circle cx="9" cy="9" r="2" />
      <path d="m21 15-3.086-3.086a2 2 0 0 0-2.828 0L6 21" />
    </svg>
  );
}

function MegaphoneIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m3 11 18-5v12L3 14v-3z" />
      <path d="M11.6 16.8a3 3 0 1 1-5.8-1.6" />
    </svg>
  );
}

function PaletteIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="13.5" cy="6.5" r=".5" fill="currentColor" />
      <circle cx="17.5" cy="10.5" r=".5" fill="currentColor" />
      <circle cx="8.5" cy="7.5" r=".5" fill="currentColor" />
      <circle cx="6.5" cy="12.5" r=".5" fill="currentColor" />
      <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10c.926 0 1.648-.746 1.648-1.688 0-.437-.18-.835-.437-1.125-.29-.289-.438-.652-.438-1.125a1.64 1.64 0 0 1 1.668-1.668h1.996c3.051 0 5.555-2.503 5.555-5.554C21.965 6.012 17.461 2 12 2z" />
    </svg>
  );
}
