import React, { useState, useEffect } from "react";
import loaderImg from "../assets/images/loader.gif";
import Footertext from "../components/includes/FooterText";
import Loader from "../components/includes/Loader";
import { useNavigate } from "react-router-dom";

import FormTitle from "../components/includes/FormTitle";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

import { useForm } from "react-hook-form";
import TextArea from "../components/core/textArea";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";

import { sendRequest } from "../services/apiService";
import { DateObject } from "react-multi-date-picker";
import { addDays, format, subDays } from "date-fns";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Textarea } from "@/components/ui/textarea";
import { DateRange } from "react-day-picker";

import { CalendarIcon } from "lucide-react";
import { DatePickerWithRange } from "@/components/includes/daterangepicker";
import { ToastContainer, toast } from "react-toastify";
const NUMBER_OF_SUGGESTIONS = 5;

const ReviewAnalysisDb = () => {
  const navigate = useNavigate();

  const suggestions_list = [...Array(NUMBER_OF_SUGGESTIONS + 1).keys()]
    .slice(1)
    .map((i) => {
      return { optionName: `${i}`, optionValue: `${i}` };
    });
  const VerifiedList = [
    {
      optionName: "All Reviews",
      optionValue: "-1",
    },
    {
      optionName: "Verified",
      optionValue: "1",
    },
  ];
  const CountryList = [
    {
      optionName: "All Marketplaces",
      optionValue: "-1",
    },
    {
      optionName: "United States",
      optionValue: "US",
    },
    {
      optionName: "United Kingdom",
      optionValue: "UK",
    },
    {
      optionName: "France",
      optionValue: "FR",
    },
    {
      optionName: "Germany",
      optionValue: "DE",
    },
    {
      optionName: "Italy",
      optionValue: "IT",
    },
    {
      optionName: "Australia",
      optionValue: "AU",
    },
    {
      optionName: "Belgium",
      optionValue: "BE",
    },
    {
      optionName: "Brazil",
      optionValue: "BR",
    },
    {
      optionName: "Canada",
      optionValue: "CA",
    },
    {
      optionName: "China",
      optionValue: "CN",
    },
    {
      optionName: "Egypt",
      optionValue: "EG",
    },
    {
      optionName: "India",
      optionValue: "IN",
    },
    {
      optionName: "Japan",
      optionValue: "JP",
    },
    {
      optionName: "Mexico",
      optionValue: "MX",
    },
    {
      optionName: "Netherlands",
      optionValue: "NL",
    },
    {
      optionName: "Poland",
      optionValue: "PL",
    },
    {
      optionName: "Saudi Arabia",
      optionValue: "SA",
    },
    {
      optionName: "Singapore",
      optionValue: "SG",
    },
    {
      optionName: "Spain",
      optionValue: "ES",
    },
    {
      optionName: "Sweden",
      optionValue: "SE",
    },
    {
      optionName: "Turkey",
      optionValue: "TR",
    },
    {
      optionName: "UAE",
      optionValue: "AE",
    },
  ];
  const defaultDate = {
    from: subDays(new Date(), 365),
    to: new Date(),
  };
  const [showLoader, setShowLoader] = useState(false);
  const [verified, setVerified] = useState(-1);
  const [response, setResponse] = useState({});
  const [requestDone, setRequestDone] = useState(false);
  const [requestData, setRequestData] = useState({});
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({ mode: "onChange" });
  useEffect(() => {
    setValue("amazon_marketplace", "US");
    setValue("review_stars", "all_stars");
  }, []);

  const onSubmit = async (data) => {
    setShowLoader(true);
    console.log(data.reviewDateRange);
    if (!data.reviewDateRange) {
      data.start_date = defaultDate.from;
      data.end_date = defaultDate.to;
    } else {
      data.start_date = data.reviewDateRange.from;
      data.end_date = data.reviewDateRange.to;
    }
    const requestBody = Object.fromEntries(
      Object.entries(data).filter(([key, value]) => value !== "-1")
    );
    const response = await sendRequest(
      requestBody,
      "customer_review_analysis_db"
    );
    if (response.status === 200) {
      setResponse(response.data);
      setRequestData(requestBody);
      setShowLoader(false);
      setRequestDone(true);
      const heading = `### **Review Analysis (Database)**`;
      const asinString = `### **Asin**: ${requestBody.asin}`;
      const reviewListString = response.data.review_list
        .map(
          (review, index) =>
            `# **${index + 1} Review Title**: ${review[0]}
  
      ${review[1]}
      
      - ${review[2]}
      - ${review[3]}
      - ${review[4].toUpperCase()}
      - ${review[6]}
      - [${review[7]}](${review[7]})
      `
        )
        .join("\n");
      const promptString = `**Prompt**: ${response.data.prompt}`;
      const suggestionsString = response.data.suggestions.join("\n");
      const numberOfReviews = `**Number of reviews:** ${response.data.review_list.length}`;
      const title = `**Title:** ${response.data.title}`;
      const finalString = [
        heading,
        title,
        numberOfReviews,
        asinString,
        reviewListString,
        suggestionsString,
        promptString,
      ].join("\n");

      navigate("/results", {
        state: { data: finalString, thread_id: response.data.thread_id },
      });
    }

    setShowLoader(false);
    setRequestDone(false);
    toast.error(
      `Asin/Marketplace combination or reviews do not exist within this date range`,
      {
        position: "top-center",
      }
    );
    // setError("asin", {
    //   type: "custom",
    //   message: "ASIN or reviews do not exist",
    // });
  };

  const StarsList = [
    {
      optionName: "All Stars",
      optionValue: "all_stars",
    },
    {
      optionName: "Five Star",
      optionValue: "five_star",
    },
    {
      optionName: "Four Star",
      optionValue: "four_star",
    },
    {
      optionName: "Three Star",
      optionValue: "three_star",
    },
    {
      optionName: "Two Star",
      optionValue: "two_star",
    },
    {
      optionName: "One Star",
      optionValue: "one_star",
    },
    {
      optionName: "All Positive",
      optionValue: "all_positive",
    },
    {
      optionName: "All Critical",
      optionValue: "all_critical",
    },
  ];
  return (
    <div className={`flex flex-col flex-grow h-screen`}>
      <ToastContainer />

      <h1 className="flex text-3xl font-bold tracking-tighter sm:text-5xl xl:text-4xl/none justify-center pb-4">
        Review Analysis DB
      </h1>
      <div className="flex flex-grow overflow-auto p-4 space-y-10 justify-center">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className=" flex-col w-[550px] space-y-5">
            <div className="space-y-2 ">
              <Input
                {...register("asin", {
                  required: {
                    value: true,
                    message: "Please fill in the ASIN field",
                  },
                  minLength: {
                    value: 10,
                    message: "Please enter a 10-digit ASIN",
                  },
                  maxLength: {
                    value: 10,
                    message: "Please enter a 10-digit ASIN",
                  },
                })}
                Label="Enter ASIN #(Required):"
                placeholder="Enter the exact Amazon ASIN"
                errors={errors}
                inputId="asin"
              />
              {errors.asin && (
                <span className="text-destructive text-xs">
                  {errors.asin.message}
                </span>
              )}
            </div>
            <div className="space-y-2">
              <Select
                defaultValue="US"
                inputId="amazon_marketplace"
                onValueChange={(e) => setValue("amazon_marketplace", e)}
              >
                <SelectTrigger>
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {CountryList.map((country) => (
                      <SelectItem value={country.optionValue}>
                        {country.optionName}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            <div className="space-y-2">
              <Select
                defaultValue="all_stars"
                inputId="review_stars"
                onValueChange={(e) => setValue("review_stars", e)}
              >
                <SelectTrigger>
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {StarsList.map((stars) => (
                      <SelectItem value={stars.optionValue}>
                        {stars.optionName}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            <div className="space-y-2">
              <Label>Choose All reviews/Verified</Label>
              <Select
                defaultValue="-1"
                inputId="review_type"
                onValueChange={(e) => setValue("review_type", e)}
              >
                <SelectTrigger>
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {VerifiedList.map((item) => (
                      <SelectItem value={item.optionValue}>
                        {item.optionName}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            <div className="space-y-2">
              <DatePickerWithRange
                className={"w-full"}
                defaultDate={defaultDate}
                setValue={setValue}
              ></DatePickerWithRange>
            </div>
            <div className="space-y-2 ">
              <Input
                {...register("num_reviews", {
                  required: {
                    value: true,
                    message: "Please fill in number of reviews",
                  },
                })}
                Label="Number of reviews"
                placeholder="10"
                defaultValue="10"
                errors={errors}
                inputId="num_reviews"
              />
              {errors.num_reviews && (
                <span className="text-destructive text-xs">
                  {errors.num_reviews.message}
                </span>
              )}
            </div>

            <div className="space-y-2">
              <Textarea
                id="prompt"
                placeholder="Prompt Instructions"
                {...register("prompt")}
              />
              If no specific prompt is given, then you will receive a report
              with the following information: (A) impression on public, (B)
              complaints, (C) 3 top positive reviews, (D) 3 top negative
              reviews, and (E) product improvement suggestions.{" "}
            </div>
            <div>
              <Button className="w-full">Submit</Button>
            </div>
          </div>
        </form>
      </div>
      <Loader showLoader={showLoader} />
    </div>
  );
};

export default ReviewAnalysisDb;

/*<>
      <div
        className={`container-xxl main__wrapper ${
          !showLoader && !requestDone ? "" : "d-none"
        } `}
        id="generator_form_container"
      >
        {" "}
        <div className="row justify-content-center">
          <div className="col-lg-5 col-md-6 col-sm-8">
            <FormTitle
              title="Amazon Review Analyzer"
              subTitle="Fill the necessary field to proceed"
            />
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="form__container mb-4"
            >
              <Input
                inputProps={register("asin", {
                  required: {
                    value: true,
                    message: "Please fill in the ASIN field",
                  },
                  minLength: {
                    value: 10,
                    message: "Please enter a 10-digit ASIN",
                  },
                  maxLength: {
                    value: 10,
                    message: "Please enter a 10-digit ASIN",
                  },
                })}
                Label="Enter ASIN #(Required):"
                placeholder="Enter the exact Amazon ASIN"
                errors={errors}
                inputId="asin"
              />
              <Select
                SelectData={CountryList}
                label="Country (Amazon Domain):"
                selectId="amazon_domain"
                inputProps={register("amazon_domain")}
              />
              <Select
                SelectData={StarsList}
                label="Filter Comments by All Positive, All Critical or Number of Stars:"
                selectId="review_stars"
                inputProps={register("review_stars")}
              />
              <DateRange
                selectId="review_stars"
                value={value}
                onChange={setValue}
                label="Reviews Date Range:"
              />

              <Input
                inputProps={register("num_reviews", {
                  minLength: {
                    value: 1,
                    message: "You can enter minimum 1 Review",
                  },
                  maxLength: {
                    value: 500,
                    message: "You can enter maximum 500 Reviews",
                  },
                })}
                Label="Number of reviews (optional):"
                placeholder="Number of reviews you want analyzed. Use an Arabic numeral. (optional) (Default is 1)"
                errors={errors}
                inputId="num_reviews"
                defaultValue="50"
              >
                <div className="form-text mt-3">
                  Number of reviews. You can ask for maximum of 500
                  reviews.(optional) (Default is 50)
                </div>
              </Input>

              <TextArea
                inputProps={register("prompt")}
                TextAreaId="prompt"
                Label="Prompt/Instructions ...for analysis based on the customer reviews (optional):"
                placeholder="Provide additional instructions"
                errors={errors}
              >
                <p
                  className="form-label mb-3 "
                  style={{ fontSize: "12px", lineHeight: "1" }}
                >
                  <br />
                  Example prompts inlcude: <br /> 1. List in bullet points the
                  top dissatisfiers based on review content (e.g., product
                  defects, delivery issues). This section should have a title
                  above it that says "Top Dissatisfiers Based on Customer
                  Reviews". 2. Next, in a new section entitled "Top 5
                  Dissatisfiers" create a simple table that shows the exact
                  count of how many times each of the top 5 dissatisfiers
                  appeared in a review. If there are dissatisfiers that are
                  clearly related to the same underlying cause, they should be
                  counted in the same category. For example, for a dog chew
                  product, if there are 10 reviews that mention 'mold' and 5
                  reviews that mention "bad smell", then you should have a row
                  for 'mold/bad smell' with a total count of 15. 3. Next, in a
                  section entitled " Summary of Relevant Review" for each top
                  dissatisfier, summarize three representative reviews, and give
                  the date a title of the review 4. Last, in a section entitled
                  "Insights and Product Improvement Suggestions" add any
                  additional insights and product improvement suggestions based
                  on the analysis
                  <br />
                  <br /> If no specific prompt is given, then you will receive a
                  report with the following information: (A) impression on
                  public, (B) complaints, (C) 3 top positive reviews, (D) 3 top
                  negative reviews, and (E) product improvement suggestions.{" "}
                </p>
              </TextArea>
              <Button disabled={isSubmitting} label="Submit" type="submit" />
            </form>
            <Footertext />
          </div>
        </div>
      </div>
      {Object.keys(response).length > 0 && !showLoader && (
        <Home messages={response} />
      )}

      <Loader
        showLoader={showLoader}
        setShowLoader={setShowLoader}
        loaderImg={loaderImg}
      />
    </> */
