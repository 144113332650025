export default function TextArea({
  Label,
  additionalClass,
  placeholder,
  inputProps,
  errors,
  children,
  TextAreaId,
}) {
  return (
    <div className="mb-4">
      {Label && (
        <label htmlFor={TextAreaId} className={`form-label mb-3`}>
          {Label}
        </label>
      )}
      <div className="position-relative d-block">
        <textarea
          {...inputProps}
          rows={5}
          id={TextAreaId}
          className={`form-control p-3 ${additionalClass}`}
          placeholder={placeholder ? placeholder : "Please Enter"}
        />
        {errors?.[inputProps.name] && (
          <span className="required_pop">
            {errors[inputProps.name].message}
          </span>
        )}
      </div>
      {children}
    </div>
  );
}
