export const Logo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="192"
    height="40"
    viewBox="0 0 192 40"
    fill="none"
  >
    <path
      d="M70.3936 15.5653V30.8976H71.9719H73.5352L73.5803 29.169L73.6254 27.4404L74.2718 28.2671C75.2037 29.4546 76.7821 30.5218 78.3153 31.0028C79.4427 31.3486 79.7433 31.3787 81.4119 31.3185C82.885 31.2584 83.4561 31.1682 84.3581 30.8525C88.6872 29.3193 91.0625 25.0353 90.7919 19.2631C90.6412 16.2116 89.7697 13.8517 88.071 12.0178C86.357 10.1388 84.2829 9.1768 81.6073 9.01143C78.9767 8.84607 76.7519 9.62778 75.0534 11.3414C74.4972 11.8825 73.941 12.4988 73.8057 12.7092C73.5803 13.055 73.5652 12.6641 73.5652 6.65146L73.5502 0.23291H71.9719H70.3936V15.5653ZM81.5471 11.8224C84.9743 12.3485 86.9433 14.6784 87.3944 18.7219C87.8157 22.3596 86.7178 25.6966 84.5835 27.3201C83.4862 28.1468 82.434 28.5076 80.9158 28.5978C77.9244 28.7782 75.7147 27.5907 74.4221 25.0954C73.5953 23.4719 73.4751 22.8557 73.4901 20.0748C73.4901 17.805 73.5202 17.5044 73.881 16.5273C74.3169 15.2797 75.1436 13.9719 75.9252 13.2955C76.8873 12.4387 78.661 11.7773 80.2093 11.672C80.3596 11.672 80.9609 11.7322 81.5471 11.8224Z"
      fill="white"
    />
    <path
      d="M168.25 6.64707L168.234 13.0807L167.919 12.6297C167.228 11.6527 165.815 10.4501 164.748 9.92398C163.245 9.18747 161.756 8.88679 160.073 9.00704C155.833 9.29266 152.662 11.9984 151.384 16.4027C151.084 17.4398 151.039 17.9208 151.039 20.2207C151.039 22.5206 151.084 23.0016 151.384 24.0539C152.451 27.7967 154.917 30.307 158.284 31.1187C159.908 31.5095 162.553 31.3743 163.951 30.8331C165.348 30.307 166.731 29.2848 167.559 28.1725L168.25 27.2556V29.0744V30.8933H169.828H171.406V15.5609V0.228516H169.828H168.25V6.64707ZM162.537 11.803C163.936 12.0284 165.514 12.8402 166.356 13.7722C167.739 15.3204 168.4 17.3948 168.4 20.2057C168.4 25.3315 165.679 28.6235 161.456 28.6385C158.93 28.6385 156.901 27.6765 155.713 25.8727C154.827 24.5348 154.391 22.9865 154.301 20.8972C154.105 15.6961 156.194 12.4944 160.253 11.803C161.26 11.6376 161.44 11.6376 162.537 11.803Z"
      fill="white"
    />
    <path
      d="M36.3424 8.87615C35.0346 9.11666 34.3582 9.40228 33.1106 10.229C32.1636 10.8604 30.7506 12.2133 29.9238 13.2956L29.548 13.7916L29.4428 12.3184C29.3827 11.4917 29.3075 10.5146 29.2625 10.1087L29.1723 9.40228H26.8574H24.5425V20.15V30.8977H27.0979H29.6533L29.6833 22.3747L29.7284 13.8517L31.4571 13.4308C33.5014 12.9197 35.7562 12.7393 36.6881 12.9949C37.575 13.2354 38.3566 13.987 38.522 14.7385C38.5971 15.0693 38.6724 18.8422 38.6724 23.1112V30.8977H41.3179H43.9635L43.8884 22.6001C43.8282 15.911 43.7681 14.0772 43.5877 13.2053C42.9864 10.4245 41.814 9.22194 39.2735 8.81606C38.0711 8.62066 37.6802 8.62066 36.3424 8.87615Z"
      fill="white"
    />
    <path
      d="M114.896 9.11747C110.747 9.61355 107.891 12.0336 107.576 15.3106L107.485 16.3176H109.063H110.627L110.807 15.4007C111.258 12.9957 112.881 11.8532 116.068 11.6879C118.864 11.5526 120.593 12.229 121.389 13.7473C121.901 14.7243 121.976 16.1222 121.54 16.513C120.954 17.0542 119.42 17.5653 116.308 18.2718C114.49 18.6927 112.581 19.1587 112.039 19.3391C108.402 20.5115 106.764 22.5559 106.974 25.7426C107.154 28.7188 109.093 30.703 112.385 31.3043C114.655 31.7101 117.391 31.2442 119.09 30.1619C119.811 29.6959 121.044 28.4783 121.54 27.7418C121.78 27.381 121.795 27.4261 121.795 29.1247L121.811 30.8985H123.388H124.967V23.3375C124.967 18.8279 124.907 15.4007 124.801 14.8145C124.471 12.7401 123.194 10.8611 121.525 10.0044C120.022 9.25279 117.105 8.84692 114.896 9.11747ZM121.795 21.3082C121.705 24.1492 121.615 24.5701 120.713 25.9831C119.525 27.8319 117.301 28.9293 114.76 28.9444C111.98 28.9444 110.251 27.5313 110.236 25.2465C110.236 23.4728 111.273 22.2101 113.362 21.4284C113.828 21.2481 115.331 20.8422 116.7 20.5266C119.285 19.9253 120.532 19.5194 121.285 19.0384C121.54 18.873 121.765 18.7378 121.811 18.7378C121.856 18.7227 121.84 19.8953 121.795 21.3082Z"
      fill="white"
    />
    <path
      d="M138.491 9.10163C136.101 9.52248 134.388 10.8904 133.23 13.3255L132.779 14.2876V11.8374V9.40223H131.2H129.623V20.15V30.8976H131.2H132.779V25.7417C132.779 19.0677 132.989 17.2939 133.982 15.1744C134.613 13.8667 135.469 12.9648 136.657 12.3786C137.589 11.9125 137.77 11.8825 139.167 11.8825C140.43 11.8825 140.791 11.9426 141.468 12.2433C142.565 12.7393 143.512 13.8215 143.932 15.0843C144.248 16.0613 144.263 16.3318 144.323 23.487L144.368 30.8976H145.947H147.526L147.48 23.1112C147.42 14.6633 147.42 14.6633 146.518 12.7694C145.211 10.0035 141.949 8.50034 138.491 9.10163Z"
      fill="white"
    />
    <path
      d="M180.855 9.17313C178.736 9.59398 176.706 11.022 175.864 12.6604C175.368 13.6676 175.173 14.9302 175.338 16.1328C175.564 17.6661 176.15 18.583 177.473 19.4698C178.615 20.2364 179.548 20.5672 182.734 21.3639C187.89 22.6565 188.852 23.2578 188.852 25.2721C188.852 27.5268 187.123 28.8346 184.117 28.8496C181.727 28.8647 179.652 28.083 178.12 26.625C177.638 26.159 177.217 25.7832 177.188 25.7832C177.007 25.7832 174.722 27.1811 174.722 27.2863C174.722 27.5569 176.586 29.2706 177.412 29.7365C180.043 31.2848 183.366 31.856 186.267 31.2547C189.137 30.6684 190.987 29.1052 191.738 26.625C192.339 24.6408 191.843 22.5814 190.446 21.3037C189.167 20.1313 187.274 19.3646 183.501 18.5079C182.253 18.2222 180.855 17.8314 180.374 17.6661C179.322 17.2602 178.525 16.5086 178.39 15.7871C178.104 14.2538 178.75 12.9461 180.133 12.1794C180.855 11.7886 181.141 11.7285 182.479 11.6834C184.853 11.5932 186.732 12.2847 188.311 13.833L189.108 14.6146L190.325 13.8781C191.001 13.4722 191.558 13.0814 191.558 13.0062C191.558 12.7807 189.634 11.1422 188.672 10.5711C186.357 9.15807 183.501 8.647 180.855 9.17313Z"
      fill="white"
    />
    <path
      d="M0.5 17.0559C0.5 21.5504 0.560127 25.2032 0.650317 25.8946C1.04114 28.7656 2.0182 30.3139 4.06252 31.2459C5.03958 31.6969 7.65511 31.7118 8.96287 31.291C10.6765 30.7348 12.5555 29.1866 13.773 27.3226L14.4043 26.3756L14.5096 28.0141C14.5848 28.9159 14.6749 29.9231 14.75 30.2387L14.8703 30.825L17.4708 30.8701L20.0713 30.9002L19.9811 30.4943C19.7106 29.2617 19.5903 25.4587 19.5903 17.8526V9.40479H17.0349H14.4795V17.7023V26.0148L13.8181 26.4358C12.7358 27.1122 11.5333 27.4579 9.89481 27.5481C7.45969 27.6833 6.45257 27.2174 5.95652 25.7443C5.73105 25.0528 5.70098 24.0156 5.64085 17.1762L5.59576 9.40479H3.04037H0.5V17.0559Z"
      fill="white"
    />
    <path
      d="M48.7511 15.5191C50.0589 18.9012 51.9378 23.7715 52.9449 26.3719C53.937 28.9724 54.7638 31.1971 54.7638 31.3324C54.7638 31.7834 53.7416 33.7225 53.1554 34.3689C52.3136 35.3158 51.5469 35.6015 49.6229 35.6766L47.9995 35.7518V37.6758C47.9995 38.728 48.0596 39.63 48.1348 39.6901C48.5556 39.9456 50.8254 40.0959 52.028 39.9306C55.0795 39.5398 56.8532 37.9164 58.5217 33.993C58.8373 33.2415 60.9268 27.5144 63.1515 21.2762C65.3912 15.0381 67.2551 9.807 67.3153 9.6567C67.4205 9.41619 67.2702 9.40112 64.835 9.43117L62.2345 9.47627L59.9948 16.8118C58.4165 22.0128 57.71 24.6133 57.5296 25.7557C57.4093 26.6426 57.2741 27.3791 57.244 27.4091C57.229 27.4242 57.0937 26.7929 56.9434 25.9811C56.7179 24.6884 55.5454 20.8703 52.6443 11.8363L51.8476 9.40112H49.1269H46.3911L48.7511 15.5191Z"
      fill="white"
    />
    <path
      d="M94.5952 20.15V30.8977H96.1586H97.7373L97.7825 24.0132C97.8276 17.8652 97.8573 17.0385 98.1127 16.1065C98.8344 13.4759 100.428 12.4087 103.66 12.4087H105.118V10.8905V9.35718L103.509 9.43233C100.774 9.56765 99.1799 10.4846 98.1884 12.4988C97.9331 13.0249 97.7076 13.4608 97.6922 13.4608C97.6922 13.4608 97.6769 12.559 97.6769 11.4767V9.47743L96.1442 9.43233L94.5952 9.3873V20.15Z"
      fill="white"
    />
  </svg>
);

export const Logout = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    className="bi bi-box-arrow-right"
    viewBox="0 0 16 16"
  >
    <path
      fillRule="evenodd"
      d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"
    />
    <path
      fillRule="evenodd"
      d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
    />
  </svg>
);

export const CardIcon1 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
  >
    <path
      opacity="0.9"
      d="M27.9028 37.3875L21.8159 31.0125L23.821 28.9125L27.9028 33.1875L35.9949 24.7125L38 26.8125L27.9028 37.3875ZM35.7801 22.5H32.9156V15H30.0512V19.5H15.7289V15H12.8645V36H21.4578V39H12.8645C12.0767 39 11.4024 38.7063 10.8414 38.1188C10.2805 37.5312 10 36.825 10 36V15C10 14.175 10.2805 13.4688 10.8414 12.8813C11.4024 12.2938 12.0767 12 12.8645 12H18.844C19.1066 11.125 19.6198 10.4062 20.3836 9.84375C21.1475 9.28125 21.983 9 22.89 9C23.8448 9 24.6982 9.28125 25.4501 9.84375C26.202 10.4062 26.7093 11.125 26.9719 12H32.9156C33.7033 12 34.3777 12.2938 34.9386 12.8813C35.4996 13.4688 35.7801 14.175 35.7801 15V22.5ZM22.89 15C23.2958 15 23.636 14.8563 23.9105 14.5688C24.185 14.2812 24.3223 13.925 24.3223 13.5C24.3223 13.075 24.185 12.7188 23.9105 12.4313C23.636 12.1438 23.2958 12 22.89 12C22.4842 12 22.1441 12.1438 21.8696 12.4313C21.5951 12.7188 21.4578 13.075 21.4578 13.5C21.4578 13.925 21.5951 14.2812 21.8696 14.5688C22.1441 14.8563 22.4842 15 22.89 15Z"
      fill="white"
    />
  </svg>
);

export const CardIcon2 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
  >
    <mask
      id="mask0_552_282"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="7"
      y="7"
      width="34"
      height="34"
    >
      <rect x="7" y="7" width="33.9912" height="33.9912" fill="white" />
    </mask>
    <g mask="url(#mask0_552_282)">
      <path
        d="M25.447 9.90356C28.8225 10.234 31.6964 11.5854 34.0687 13.9577C36.441 16.33 37.7924 19.2039 38.1229 22.5795H27.9963C27.7839 21.9657 27.4593 21.4287 27.0226 20.9684C26.5859 20.5081 26.0607 20.1717 25.447 19.9593V9.90356ZM28.2796 13.5151V18.3306C28.5392 18.543 28.7871 18.7672 29.0231 19.0033C29.2592 19.2393 29.4834 19.4872 29.6959 19.7469H34.5113C33.9448 18.3306 33.1186 17.0795 32.0328 15.9937C30.9469 14.9078 29.6959 14.0817 28.2796 13.5151ZM22.6144 9.90356V19.9593C21.7646 20.2662 21.0801 20.7914 20.5607 21.5349C20.0414 22.2785 19.7818 23.0988 19.7818 23.9958C19.7818 24.8927 20.0414 25.7012 20.5607 26.4212C21.0801 27.1411 21.7646 27.6545 22.6144 27.9614V38.0879C18.9792 37.7339 15.946 36.2113 13.5146 33.5204C11.0833 30.8294 9.86768 27.6545 9.86768 23.9958C9.86768 20.337 11.0833 17.1621 13.5146 14.4711C15.946 11.7802 18.9792 10.2576 22.6144 9.90356ZM19.7818 13.5151C17.6337 14.3413 15.9165 15.7104 14.63 17.6224C13.3435 19.5344 12.7003 21.6589 12.7003 23.9958C12.7003 26.3326 13.3435 28.4571 14.63 30.3691C15.9165 32.2811 17.6337 33.6738 19.7818 34.5472V29.661C18.8848 28.9764 18.1884 28.1443 17.6927 27.1647C17.197 26.1851 16.9492 25.1288 16.9492 23.9958C16.9492 22.8627 17.197 21.8064 17.6927 20.8268C18.1884 19.8472 18.8848 19.0151 19.7818 18.3306V13.5151ZM27.9963 25.4121H38.1229C37.7924 28.7876 36.441 31.6615 34.0687 34.0338C31.6964 36.4061 28.8225 37.7575 25.447 38.0879V27.9614C26.0607 27.7489 26.5859 27.4244 27.0226 26.9877C27.4593 26.551 27.7839 26.0258 27.9963 25.4121ZM29.6959 28.2447C29.507 28.5043 29.2887 28.7522 29.0408 28.9882C28.793 29.2243 28.5392 29.4485 28.2796 29.661V34.4764C29.6959 33.9099 30.9469 33.0837 32.0328 31.9979C33.1186 30.912 33.9448 29.661 34.5113 28.2447H29.6959Z"
        fill="white"
      />
    </g>
  </svg>
);

export const CopyIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="20"
    viewBox="0 0 17 20"
    fill="none"
  >
    <path
      d="M2 20C1.45 20 0.979167 19.8042 0.5875 19.4125C0.195833 19.0208 0 18.55 0 18H2V20ZM0 16.5V14.5H2V16.5H0ZM0 13V11H2V13H0ZM0 9.5V7.5H2V9.5H0ZM0 6C0 5.45 0.195833 4.97917 0.5875 4.5875C0.979167 4.19583 1.45 4 2 4V6H0ZM3.5 20V18H5.5V20H3.5ZM6 16C5.45 16 4.97917 15.8042 4.5875 15.4125C4.19583 15.0208 4 14.55 4 14V2C4 1.45 4.19583 0.979167 4.5875 0.5875C4.97917 0.195833 5.45 0 6 0H15C15.55 0 16.0208 0.195833 16.4125 0.5875C16.8042 0.979167 17 1.45 17 2V14C17 14.55 16.8042 15.0208 16.4125 15.4125C16.0208 15.8042 15.55 16 15 16H6ZM6 14H15V2H6V14ZM7 20V18H9V20H7ZM10.5 20V18H12.5C12.5 18.55 12.3042 19.0208 11.9125 19.4125C11.5208 19.8042 11.05 20 10.5 20Z"
      fill="white"
    />
  </svg>
);

export const RegenerateIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="20"
    viewBox="0 0 16 20"
    fill="none"
  >
    <path
      d="M7 19.2249C4.98333 18.9749 3.3125 18.0957 1.9875 16.5874C0.6625 15.0791 0 13.3082 0 11.2749C0 10.1749 0.216667 9.12074 0.65 8.1124C1.08333 7.10407 1.7 6.2249 2.5 5.4749L3.925 6.8999C3.29167 7.46657 2.8125 8.1249 2.4875 8.8749C2.1625 9.6249 2 10.4249 2 11.2749C2 12.7416 2.46667 14.0374 3.4 15.1624C4.33333 16.2874 5.53333 16.9749 7 17.2249V19.2249ZM9 19.2249V17.2249C10.45 16.9582 11.6458 16.2666 12.5875 15.1499C13.5292 14.0332 14 12.7416 14 11.2749C14 9.60824 13.4167 8.19157 12.25 7.0249C11.0833 5.85824 9.66667 5.2749 8 5.2749H7.925L9.025 6.3749L7.625 7.7749L4.125 4.2749L7.625 0.774902L9.025 2.1749L7.925 3.2749H8C10.2333 3.2749 12.125 4.0499 13.675 5.5999C15.225 7.1499 16 9.04157 16 11.2749C16 13.2916 15.3375 15.0541 14.0125 16.5624C12.6875 18.0707 11.0167 18.9582 9 19.2249Z"
      fill="white"
    />
  </svg>
);

export const SelectDropIcon = () => (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1928 4.74973C10.3337 4.6089 10.5247 4.52979 10.7238 4.52979C10.923 4.52979 11.114 4.6089 11.2548 4.74973C11.3957 4.89056 11.4748 5.08157 11.4748 5.28073C11.4748 5.4799 11.3957 5.6709 11.2548 5.81173L6.52985 10.5357C6.38922 10.6762 6.1986 10.7551 5.99985 10.7551C5.8011 10.7551 5.61048 10.6762 5.46985 10.5357L0.74485 5.81173C0.60402 5.6709 0.524902 5.4799 0.524902 5.28073C0.524902 5.08157 0.60402 4.89056 0.74485 4.74973C0.88568 4.6089 1.07669 4.52979 1.27585 4.52979C1.47501 4.52979 1.66602 4.6089 1.80685 4.74973L5.99985 8.94273L10.1928 4.74973Z"
      fill="#fff"
    />
  </svg>
);

export const BackArrowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M19 12H5M5 12L12 19M5 12L12 5"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
