import { SelectDropIcon } from "../iconset";

export default function Select({
  selectId,
  label,
  SelectData,
  inputProps,
  errors,
}) {
  return (
    <div className="mb-4 selectDrop">
      <label htmlFor={selectId} className="form-label mb-3">
        {label}
      </label>
      <select {...inputProps} className="form-control p-3" id={selectId}>
        {SelectData.map((data, index) => (
          <option key={index} value={data.optionValue}>
            {data.optionName}
          </option>
        ))}
      </select>
      <span className="selectDropIcon">
        <SelectDropIcon />
      </span>
      {errors?.[inputProps.name] && (
        <span className="required_pop">{errors[inputProps.name].message}</span>
      )}
    </div>
  );
}
